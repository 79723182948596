export async function loadCss(href: string) {
    return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.addEventListener('load', resolve);
        link.addEventListener('error', reject);
        link.href = href;
        document.head.appendChild(link);
    });
}

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
