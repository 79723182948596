import type { MultiCurrencyCheckoutRequest } from './checkout/types';

// Redefining these enums from the HubApi to avoid loading the entire HubApi and its dependencies if we only need the
// enums, for example in the Buyer view.
export enum Currency {
    NIM = 'nim',
    BTC = 'btc',
    USDC = 'usdc',
}
export enum LegacyCurrency {
    ETH = 'eth',
}

export enum PaymentType {
    DIRECT,
    OASIS,
}
export enum PaymentState {
    NOT_FOUND = 'NOT_FOUND',
    PAID = 'PAID',
    UNDERPAID = 'UNDERPAID',
    OVERPAID = 'OVERPAID',
}

export enum OrderType {
    PAYMENT = 'payment',
    DONATION = 'donation',
}

export enum OrderPurpose {
    CREATE_LINK = 'create-link',
    CHARGE_NOW = 'charge-now',
}

/* eslint-disable camelcase */
interface BaseOrder {
    brand_name: string;
    item_description: string;
    fiat_currency_code: string;
    fiat_price: number;
}

export interface CreateOrderRequest extends Omit<BaseOrder, 'fiat_price'> {
    fiat_price: number | null;
    seller_email: string;
    nim_address: string;
    btc_address: string;
    usdc_address: string;
    purpose: OrderPurpose;
}

export interface CreateFromStaffLinkRequest {
    staff_id: string;
    item_description?: string;
    fiat_currency_code: string;
    fiat_price: number;
    crypto_currency_code: 'nim' | 'btc' | 'usdc';
    purpose: OrderPurpose.CHARGE_NOW;
}

export type SellerInfos = Omit<CreateOrderRequest, 'fiat_price' | 'item_description'>

export interface Order extends BaseOrder {
    id: string;
    link: string;
    status: 'pending' | 'paying' | 'on-hold' | 'confirmed';
    accepted_currencies: string[];
    user?: string;
    return_url?: string;
    has_buyer_email?: boolean;
    order_type: OrderType;
}

export interface CreateOrderResponse {
    status: string;
    order: Order;
    checkoutOptions: Required<MultiCurrencyCheckoutRequest>;
}

export interface Customization {
    title?: string;
    text?: string;
    logo?: boolean;
    text_shadow?: boolean;
    hide_about?: boolean;
    background_position?: string;
}

export type StaffLinkData = {
    acceptedCryptos: Currency[],
    referenceFiat: string | undefined,
    brandName: string,
}

export type GetStateResponse = {
    time: number,
    payment_state: PaymentState,
} & ({
    payment_accepted: false,
    transaction_currency?: Currency | LegacyCurrency,
    transaction_hash?: string,
} | {
    payment_accepted: true,
    transaction: {
        currency: Currency | LegacyCurrency,
        hash: string,
        sender: string | null,
        recipient: string,
        value: string,
        block_height?: number,
        confirmations?: number,
        message: string,
    },
    explorer: {
        name: string,
        link: string,
    },
})

export interface ErrorResponse {
    status: 'ERROR';
    error: {
        field: string,
        message: string,
        code: string,
    };
}

export interface WebhookErrorResponse {
    status: 'ERROR';
    error: string;
    code: string;
}
/* eslint-enable camelcase */

export type FirebaseDocument<Document extends Order | Customization> = {
    exists: true,
    data(): Document,
} | {
    exists: false,
    data(): Record<string, unknown>,
};

export type CryptoSelectorOption = { icon: string, value: string, label: string };

export type Payment = {
    amount: number,
    currency: Currency | LegacyCurrency,
};
