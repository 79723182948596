const PROD_APP = 'checkout-service';
const STAGING_APP = 'checkout-service-staging-0';
const ALBATROSS_STAGING_APP = 'cplink-albatross-staging';

export function isLocalhost() {
    return (
        window.location.hostname === 'localhost'
        // Browserstack tunnel for iOS, https://www.browserstack.com/docs/live/local-testing/ios-troubleshooting-guide
        || window.location.hostname === 'bs-local.com'
    );
}

export const app = window.location.hostname.split('.').slice(-2).join('.') === 'cplink.com'
    ? PROD_APP
    : isLocalhost()
        ? STAGING_APP // Change this to ALBATROSS_STAGING_APP to develop locally with Albatross
        : window.location.hostname.replace('.web.app', '').replace('.firebaseapp.com', '');

export const BASE_API_URL = isLocalhost()
    ? `http://${window.location.hostname}:5001/${app}/europe-west1/server/api`
    : `${window.location.origin}/api`;

export const BASE_FIRESTORE_URL = isLocalhost()
    ? `http://${window.location.hostname}:8080/v1/projects/${app}/databases/(default)/documents`
    : `https://firestore.googleapis.com/v1/projects/${app}/databases/(default)/documents`;

export function isProd() {
    return (app === PROD_APP);
}

export function isStaging() {
    return (app === STAGING_APP || app === ALBATROSS_STAGING_APP);
}

export function getHubUrl() {
    return isProd()
        ? 'https://hub.nimiq.com'
        : app === STAGING_APP
            ? 'https://hub.nimiq-testnet.com'
            : 'https://hub.pos.nimiq-testnet.com';
}

export function getUsername(): string | null {
    if (isProd()) {
        const username = window.location.hostname.split('.')[0];
        if (username === 'cplink') return null;
        return username;
    }

    return new URLSearchParams(window.location.search).get('user');
}

export const FIREBASE_CONFIG = {
    [PROD_APP]: {
        apiKey: 'AIzaSyAhwuIt3-bqVQb-EXgH0ps21XGuJ3bAib8',
        authDomain: 'checkout-service.firebaseapp.com',
        databaseURL: 'https://checkout-service.firebaseio.com',
        projectId: 'checkout-service',
        storageBucket: 'checkout-service.appspot.com',
        messagingSenderId: '573558041652',
        appId: '1:573558041652:web:dee2ce3667c3fd5f30ee61',
        measurementId: 'G-60EJ0FMJCF',
    },
    [STAGING_APP]: {
        apiKey: 'AIzaSyD__l7Nlxx6EV8IpYcB8BLhF9HVM9Qpyyw',
        authDomain: 'checkout-service-staging-0.firebaseapp.com',
        databaseURL: 'https://checkout-service-staging-0.firebaseio.com',
        projectId: 'checkout-service-staging-0',
        storageBucket: 'checkout-service-staging-0.appspot.com',
        messagingSenderId: '432200699785',
        appId: '1:432200699785:web:ae89dce9544bbedd531b58',
    },
    [ALBATROSS_STAGING_APP]: {
        apiKey: 'AIzaSyCXzOGqlSs0Fh4CdhECzgz7KqJkpzFjoeE',
        authDomain: 'cplink-albatross-staging.firebaseapp.com',
        databaseURL: 'https://cplink-albatross-staging.firebaseio.com',
        projectId: 'cplink-albatross-staging',
        storageBucket: 'cplink-albatross-staging.appspot.com',
        messagingSenderId: '',
        appId: '1:418175933535:web:bc66de60039a2a9f310fea',
    },
}[app]!;
