import Vue from 'vue';
import { User } from 'firebase/auth';
import { getFirebaseUser } from './Firebase';

const auth = Vue.observable({ user: null as User | false | null });

const userPromise = getFirebaseUser().then((user) => {
    auth.user = user || false;
    return user;
});

export { userPromise };
export default auth;
