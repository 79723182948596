import { FirebaseDocument, Order, Customization } from './Types';
import { BASE_FIRESTORE_URL } from './Config';

export async function fetchOrder(id: string): Promise<FirebaseDocument<Order>> {
    const response = await fetch(`${BASE_FIRESTORE_URL}/orders/${id}`).then((res) => res.json());

    if (response.error) {
        return {
            exists: false,
            data: () => ({}),
        };
    }

    const { fields } = response;
    return {
        exists: true,
        data: () => ({
            id: fields.id.stringValue,
            link: fields.link.stringValue,
            brand_name: fields.brand_name.stringValue,
            item_description: fields.item_description.stringValue,
            fiat_price: fields.fiat_price.doubleValue || parseInt(fields.fiat_price.integerValue, 10),
            fiat_currency_code: fields.fiat_currency_code.stringValue,
            status: fields.status.stringValue,
            accepted_currencies: fields.accepted_currencies.arrayValue.values.map((v: any) => v.stringValue),
            user: fields.user?.stringValue,
            return_url: fields.return_url?.stringValue,
            has_buyer_email: fields.has_buyer_email?.booleanValue,
            order_type: fields.order_type?.stringValue || 'payment',
        }),
    };
}

export async function fetchCustomization(username: string): Promise<FirebaseDocument<Customization>> {
    const response = await fetch(`${BASE_FIRESTORE_URL}/customizations/${username}`).then((res) => res.json());

    if (response.error) {
        return {
            exists: false,
            data: () => ({}),
        };
    }

    const { fields } = response;
    return {
        exists: true,
        data: () => ({
            title: fields.title?.stringValue,
            text: fields.text?.stringValue,
            logo: fields.logo?.booleanValue,
            text_shadow: fields.text_shadow?.booleanValue,
            hide_about: fields.hide_about?.booleanValue,
            background_position: fields.background_position?.stringValue,
        }),
    };
}
