











import { Vue, Component, Prop } from 'vue-property-decorator';
import { ArrowRightSmallIcon, CircleSpinner } from '@nimiq/vue-components';

@Component({ components: { ArrowRightSmallIcon, CircleSpinner } })
export default class CtaButton extends Vue {
    @Prop({ type: Boolean, default: true })
    public hasArrow!: boolean;

    @Prop(Boolean)
    public isProcessing?: boolean;

    @Prop({ type: Boolean, default: false })
    public pointLeft!: boolean;

    @Prop({ type: Boolean, default: false })
    public asLink!: boolean;
}
