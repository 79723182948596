import Vue from 'vue';
import Router from 'vue-router';

const Landing = () => import(/* webpackChunkName: "landing" */ './views/Landing.vue');
const Seller = () => import(/* webpackChunkName: "sell" */ './views/Seller.vue');
const Buyer = () => import(/* webpackChunkName: "buy" */ './views/Buyer.vue');

Vue.use(Router);

export const REQUEST_ERROR = 'request-error';
export const ERROR = 'error';

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: Landing,
            name: 'root',
            alias: '/?*',
        },
        {
            path: '/app',
            component: Seller,
            name: 'app',
        },
        {
            path: '/pay/:id',
            component: Buyer,
            name: 'pay',
        },
        {
            path: '/employees/:staffId',
            alias: '/staff/:staffId',
            component: Seller,
            props: true,
            name: 'employee-link',
        },
        {
            path: '/*', // Catchall
            redirect: (target) => `/?t=${target.path.substring(1)}`,
        },
    ],
});
