import Vue from 'vue';
import router from '@/router';
import { i18n, detectLanguage, loadLanguage } from './i18n/i18n-setup';
import App from './App.vue';

Vue.config.productionTip = false;

// load and set the initial language
loadLanguage(detectLanguage());

// Redirect old dashboard domain
if (window.location.origin === 'https://my.cryptopayment.link') {
    window.location.replace('https://cplink.com/dashboard');
} else if (window.location.hostname.split('.').slice(-2).join('.') === 'cryptopayment.link') {
    // Redirect preserving path and query
    window.location.replace(window.location.href.replace('cryptopayment.link', 'cplink.com'));
} else {
    new Vue({
        render: (h) => h(App),
        router,
        i18n,
    }).$mount('body');
}

// This router navigation guard is to prevent switching to the new route before the language file finished loading.
// If there are any routes which do not require translations, they can be skipped here.
router.beforeResolve((to, from, next) => {
    loadLanguage(detectLanguage()).then(() => next());
});
