import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { I18nMixin as VueComponentsI18n } from '@nimiq/vue-components';
import { Cookie } from '@nimiq/utils';

Vue.use(VueI18n);

const DEFAULT_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = ['de', 'en', 'es', 'fr', 'pt'];
const loadedLanguages: string[] = [];

export const i18n = new VueI18n({
    locale: DEFAULT_LANGUAGE, // set locale (2 letters format: 'en')
    fallbackLocale: DEFAULT_LANGUAGE, // fallback locale if no translation found
    silentTranslationWarn: true, // disable the "no translation found" warning
});

if (process.env.NODE_ENV === 'production') {
    i18n.missing = () => ''; // trick to not show numbers instead of string before language is loaded
}

function setI18nLanguage(lang: string): string {
    Cookie.setCookie('lang', lang, {
        samesite: 'lax',
        path: '/',
    });
    i18n.locale = lang;
    document.documentElement.setAttribute('lang', lang);
    return lang;
}

// load and set language
export async function loadLanguage(lang: string): Promise<string> {
    if (!SUPPORTED_LANGUAGES.includes(lang)) {
        lang = DEFAULT_LANGUAGE;
    }

    VueComponentsI18n.setLanguage(lang);

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return setI18nLanguage(lang);
    }

    // If the language hasn't been loaded yet
    const messages = await import(/* webpackChunkName: "lang-[request]" */ `@/i18n/${lang}.po`);
    i18n.setLocaleMessage(lang, messages.default);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
}

// Return the language stored in the `lang` cookie. Fallback to the browser language
export function detectLanguage(): string {
    const langCookie = Cookie.getCookie('lang');
    const fallbackLang = window.navigator.language.split('-')[0];

    let lang = langCookie || fallbackLang;
    // If the language is not supported set it to the default one
    if (!SUPPORTED_LANGUAGES.includes(lang)) {
        lang = DEFAULT_LANGUAGE;
    }
    return lang;
}

// If the user changed the language in another window/tab then load and enable new language
function onTabFocus() {
    const lang = detectLanguage();
    if (i18n.locale !== lang) {
        loadLanguage(lang);
    }
}

window.addEventListener('focus', onTabFocus);
