import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, User as FirebaseUser } from 'firebase/auth';
import { getFirestore, getDoc, doc, DocumentReference } from 'firebase/firestore';
import { FIREBASE_CONFIG } from './Config';
import type { User } from '../../dashboard/src/lib/FirestoreTypes';

let app: FirebaseApp | undefined;

export function initFirebase() {
    if (app) return app;
    app = initializeApp(FIREBASE_CONFIG);
    return app;
}

export type AuthUser = User & { seller_email: string, id: string };

export async function getFirebaseUser() {
    const firebaseAuth = getAuth(initFirebase());

    return new Promise<FirebaseUser | null>((resolve) => {
        onAuthStateChanged(firebaseAuth, resolve);
    });
}

export async function loadFirebaseUserData(user?: FirebaseUser | false | null | Promise<FirebaseUser | null>) {
    if (user === undefined || user === null) {
        user = getFirebaseUser();
    }
    user = await user;
    if (!user) {
        return null;
    }

    const store = getFirestore(initFirebase());
    const userSettings = await getDoc(doc(store, 'users', user.uid) as DocumentReference<User>);
    if (!userSettings.exists()) {
        return null;
    }

    return {
        ...userSettings.data(),
        seller_email: user.email!,
        id: user.uid,
    };
}
