









import { Component, Vue, Prop } from 'vue-property-decorator';
import { CloseButton } from '@nimiq/vue-components';
import Terms from '@/assets/TermsAndConditions.md';

@Component({ components: {
    CloseButton,
} })
export default class TermsModal extends Vue {
    private static readonly CONTENT: string = Terms;

    @Prop({
        type: String,
    })
    public htmlContent: string | undefined;

    private readonly CONTENT = TermsModal.CONTENT;

    private isOpen: boolean = false;

    public open() {
        this.isOpen = true;
    }

    private close() {
        this.isOpen = false;
    }
}
