






























































































import '@nimiq/style/nimiq-style.min.css';
import '@nimiq/vue-components/dist/NimiqVueComponents.css';
import './scss/global.scss';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { ArrowRightSmallIcon, LanguageSelector } from '@nimiq/vue-components';
import { BrowserDetection } from '@nimiq/utils';
import { Customization } from './lib/Types';
import { fetchCustomization } from './lib/Firestore';
import { BASE_API_URL, getUsername, isStaging } from './lib/Config';
import { loadCss } from './lib/Utils';
import { SUPPORTED_LANGUAGES, detectLanguage, loadLanguage } from './i18n/i18n-setup';
import TermsModal from './components/TermsModal.vue';
import SuccessStatus from './components/SuccessStatus.vue';
import auth, { userPromise } from './lib/Auth';
import router from './router';

// Lazy load About section which is below the fold.
const About = () => import('./components/About.vue');

@Component({ components: {
    TermsModal,
    ArrowRightSmallIcon,
    LanguageSelector,
    About,
} })
export default class App extends Vue {
    private static scrollTo(scrollContainer: Window | Element, top: number) {
        try {
            scrollContainer.scrollTo({
                top,
                behavior: 'smooth',
            });
        } catch (e) {
            scrollContainer.scrollTo(0, top);
        }
    }

    public readonly $refs!: {
        termsModal: TermsModal,
        about?: InstanceType<typeof import('./components/About.vue').default>,
    };

    public readonly $el!: HTMLElement;

    private readonly SUCCESS_STATUS_EVENTS = SuccessStatus.EVENTS;
    private readonly SUPPORTED_LANGUAGES = SUPPORTED_LANGUAGES;

    private isStaging = isStaging;

    private language = detectLanguage();
    private customization: Customization | null = null;
    private logoUrl = '/img/logo.svg';
    private hideAboutSection = false;
    private hideFooter = false;
    private showSuccessBackground = false;
    private iosVirtualKeyboardScrollPositions: number[] = [];
    private brandName = '';
    private backgroundCssUrl = '';
    private router = router;

    private auth = auth;

    private async created() {
        this.createManifest();

        this.onResize = this.onResize.bind(this);
        window.addEventListener('resize', this.onResize);
        this.onResize();

        const username = getUsername();

        if (username && username !== 'vendor') {
            const customization = await fetchCustomization(username);
            if (customization.exists) {
                this.customization = customization.data();

                if (this.customization.logo) {
                    this.logoUrl = `/api/logo?variant=pay${isStaging() ? `&user=${username}` : ''}`;
                }

                if (this.customization.title) {
                    document.title = `${this.customization.title} - ${document.title}`;
                }

                if (this.customization.background_position) {
                    this.$el.style.backgroundPosition = this.customization.background_position;
                }
            }
        }
    }

    @Watch('router.currentRoute')
    private async updateBackgroundCssUrl() { // eslint-disable-line class-methods-use-this
        const username = getUsername();

        let cssUrl = `${BASE_API_URL}/background`;

        if (router.currentRoute.name !== 'root') {
            if (username && username !== 'vendor') {
                cssUrl += `?user=${username}`;
            } else if (window.location.pathname.startsWith('/pay/')) {
                cssUrl += `?orderId=${window.location.pathname.split('/')[2]}`;
            } else if (window.location.pathname.startsWith('/employees/')) {
                cssUrl += `?staffId=${window.location.pathname.split('/')[2]}`;
            } else {
                const user = await userPromise;
                if (user) {
                    cssUrl += `?uid=${user.uid}`;
                }
            }
        }

        this.backgroundCssUrl = cssUrl;
    }

    @Watch('backgroundCssUrl')
    private loadBackgroundCss() {
        if (!this.backgroundCssUrl) return;
        loadCss(this.backgroundCssUrl).catch((error) => {
            // TODO: Handle CSS load error
            console.error(error); // eslint-disable-line no-console
        });
    }

    // eslint-disable-next-line class-methods-use-this
    private createManifest() {
        // Create pwa manifest dynamically.
        // Installed employee links should open as is, including the link id, and installations from all other routes
        // should start at the root page when opened. Specify absolute paths because relative paths would be tried to be
        // resolved relative to the manifest href which is a data url.
        const manifestContent = JSON.stringify({
            name: 'CryptoPayment Link',
            short_name: 'CPLink',
            icons: [{
                src: `${window.location.origin}/img/app-icons/android-icon-96x96.png`,
                sizes: '96x96',
                type: 'image/png',
            }, {
                src: `${window.location.origin}/img/app-icons/android-icon-144x144.png`,
                sizes: '144x144',
                type: 'image/png',
            }, {
                src: `${window.location.origin}/img/app-icons/android-icon-192x192.png`,
                sizes: '192x192',
                type: 'image/png',
            }, {
                src: `${window.location.origin}/img/app-icons/ms-icon-310x310.png`,
                sizes: '310x310',
                type: 'image/png',
            }],
            display: 'standalone',
            background_color: '#000000',
            scope: window.location.origin, // all paths on the domain should be in pwa scope
            // Where to start the pwa. This also uniquely identifies the pwa installation, such that multiple
            // installations for different staff links and root are possible at the same time,
            // see https://w3c.github.io/manifest/#id-member
            start_url: /^\/(?:app|staff|employees|e)\/.+/.test(window.location.pathname)
                ? `${window.location.origin}${window.location.pathname}` // employee link w/o potential query & fragment
                : window.location.origin, // start at root
        });
        const manifestElement = document.createElement('link');
        manifestElement.setAttribute('rel', 'manifest');
        manifestElement.setAttribute('href', `data:application/manifest+json,${encodeURIComponent(manifestContent)}`);
        document.head.appendChild(manifestElement);
    }

    private destroyed() {
        window.removeEventListener('resize', this.onResize);
    }

    @Watch('language') // eslint-disable-line class-methods-use-this
    private onLanguageChange(lang: string) {
        loadLanguage(lang);
    }

    private onResize() {
        this.hideFooter = window.innerWidth <= 750; // $tablet breakpoint
    }

    private async scrollToAbout() {
        if (this.hideAboutSection) {
            this.hideAboutSection = false;
            await Vue.nextTick(); // wait for Vue to apply the change
        }

        if (!this.$refs.about) return;
        App.scrollTo(document.body, document.body.scrollTop + this.$refs.about.$el.getBoundingClientRect().top);
    }

    private async fixIosInputFocus(event: FocusEvent) {
        // Fix Safari / Chrome iOS sometimes failing to scroll the focused input element into view when the virtual
        // keyboard opens.
        // Some background info: in contrast to Android browsers where the viewport and with it the html element shrinks
        // when the keyboard is opened, on iOS only the window resizes, but the size of its contents are unchanged and
        // only scrolled within the window, to scroll the focused input into view. Unfortunately, Apple's implementation
        // only really works flawlessly if the window is the regular scrolling element of the page content (and not e.g.
        // the body) and the content is actually long enough to be scrollable. In our case however, the <body> is the
        // scrolling element and the <html>'s and <body>'s size are fixed to 100% (the size of the visible area if the
        // keyboard is not open; regular window.innerHeight), i.e. the window itself if not regularly scrollable. We
        // fix the <html>'s and <body>'s size to achieve a background-attachment: fixed effect without actually using
        // background-attachment: fixed which is broken on iOS. Under these circumstances, the scrolling behaves as
        // follows:
        // Instead of fixing the window size to the available space above the virtual keyboard and just scrolling within
        // that area as one might expect, the window size is instead variable for whatever reason and depends on the
        // scroll position. The window scroll position + window innerHeight equate to the regular window innerHeight
        // when the the keyboard is not shown (100%). This means, when the window is at the top position (not scrolled),
        // the window still has the original height and the keyboard just covers part of it. When the window is scrolled
        // such that the bottom of the content aligns with the top of the keyboard, the window size matches the space
        // above the keyboard. Unfortunately, scrolling beyond that point is also possible, probably due to a buggy
        // implementation on Apple's side. When scrolling further, the window innerHeight shrinks beyond the available
        // space which leaves an empty space between the visible content and the keyboard.
        // As our inputs are positioned towards the end of the page on mobile, scrolling the window such that the page
        // bottom aligns with the top of the keyboard and the window size matches the available space above the keyboard
        // would be ideal. Unfortunately though, I couldn't find a way to know the size of the area above the keyboard,
        // to scroll the window accordingly. The best I could come up with via experimentation on an iPhone 13 Pro with
        // iOS 15.3 to calculate the size of the available space is the following, based on these measured values:
        // - screenSize: the entire screen's size (window.outerHeight)
        // - bottomOffset: size of the keyboard + iPhone's bottom bar (equates to maximum scrolled window.scrollY which
        //   can be obtained by window.scrollTo(0, some_high_number) and then reading window.scrollY)
        // - topOffset: size of the iPhone's top bar (can be roughly obtained by measuring an element with height 100vh
        //   and subtracting that from the screen's size. This leaves apparently the top and bottom bar. Dividing by 2
        //   gives then roughly the size of the top bar)
        // The available space above the keyboard is then screenSize - topOffset - bottomOffset
        // However, this calculation is complicated and fragile, as it might need to be adapted on other iPhone models
        // or iOS versions. For this reason, we're eventually using a different approach:
        // When Safari manages to scroll the page, which is most of the cases, we store that scroll position. While
        // picking the position that aligns the bottom of the page with the top of the keyboard would be ideal, all of
        // these scroll positions look decent as all our inputs have similar positions in the UI. However, some scroll
        // positions scroll less (if the input that was focused sits higher on the page) or more (basically on the first
        // page, where the about section is still visible). But as most of our inputs sit so low that the input can't be
        // centered without reaching the page bottom, Safari aligns to the page bottom for most inputs. By storing all
        // scroll positions and picking the median, we thus have a good chance of picking the ideal value for aligning
        // the page bottom with the top of the keyboard.

        if (!BrowserDetection.isIOS()) return;
        const input = event.target as HTMLInputElement | HTMLTextAreaElement;
        if (input.tagName !== 'INPUT' && input.tagName !== 'TEXTAREA') return;
        // Wait for Safari / Chrome iOS to focus the element and hopefully scroll.
        await new Promise<void>((resolve) => {
            let timeout = -1;
            const onResolve = () => {
                window.clearTimeout(timeout);
                window.removeEventListener('scroll', onResolve);
                resolve();
            };
            timeout = window.setTimeout(onResolve, 100);
            // Note: this fires only for Safari's window scrolling for the virtual keyboard, not for regular scrolling
            // on the page as that scrolls the body element.
            window.addEventListener('scroll', onResolve);
        });

        if (window.scrollY) {
            // Safari correctly scrolled the view, great. Store the scroll position.
            this.iosVirtualKeyboardScrollPositions.push(window.scrollY);
            return;
        }

        // Safari did not scroll
        if (!this.iosVirtualKeyboardScrollPositions.length) {
            // Failed even on first focus (which never happened while testing) or the iPhone / iPad is using an external
            // keyboard in which case the virtual keyboard does not show and the page doesn't need to be scrolled.
            return;
        }
        // Insertion via binary search would be nicer, but we're not expecting a lot of entries here and Safari failing
        // to scroll also only happens sometimes.
        this.iosVirtualKeyboardScrollPositions.sort();
        const medianIndex = Math.floor((this.iosVirtualKeyboardScrollPositions.length - 1) / 2);
        const scrollPosition = this.iosVirtualKeyboardScrollPositions[medianIndex];
        App.scrollTo(window, scrollPosition);
        console.log(`iOS scroll fix. Manually scrolling to ${scrollPosition}.`); // eslint-disable-line no-console
    }

    private fixIosInputBlur() { // eslint-disable-line class-methods-use-this
        // Fix Safari / Chrome iOS not blurring the currently focused input when clicking outside of it. Strangely,
        // making the "outside element" react to clicks by adding this no-op click listener, really already seems to be
        // enough to fix the issue.
    }
}
