





























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Order, Payment } from '../lib/Types';
import OrderOverview from './OrderOverview.vue';
import CtaButton from './CtaButton.vue';

@Component({ components: {
    OrderOverview,
    CtaButton,
} })
class SuccessStatus extends Vue {
    @Prop({
        type: String as () => SuccessStatus.TYPES,
        default: () => SuccessStatus.TYPES.SELLER,
    })
    public type!: SuccessStatus.TYPES;

    @Prop(Object)
    public order?: Order;

    @Prop(String)
    public explorerLink?: string;

    @Prop(Object)
    public payment?: Payment;

    @Prop(String)
    public ctaUrl?: string;

    private readonly TYPES = SuccessStatus.TYPES;

    private mounted() {
        this.$emit(SuccessStatus.EVENTS.SHOW);
    }

    private beforeDestroy() {
        this.$emit(SuccessStatus.EVENTS.HIDE);
    }

    private visitNimiqCta() { // eslint-disable-line class-methods-use-this
        window.open('https://nimiq.com/', '_blank');
    }

    private goToDashboardCta() { // eslint-disable-line class-methods-use-this
        window.open(`${window.location.origin}/dashboard`, '_blank');
    }

    private goToCustomCtaUrl() {
        if (this.ctaUrl) window.location.assign(this.ctaUrl);
    }

    private goToExplorerLink() {
        // Open in new tab, to not lose current employee link URL
        if (this.explorerLink) window.open(this.explorerLink, '_blank');
    }
}

namespace SuccessStatus { // eslint-disable-line @typescript-eslint/no-namespace
    export enum TYPES {
        SELLER = 'seller',
        EMPLOYEE = 'employee',
        BUYER = 'buyer',
    }

    export enum EVENTS {
        SHOW = 'show-success-status',
        HIDE = 'hide-success-status',
    }
}

export default SuccessStatus;
