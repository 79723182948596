

















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Amount, FiatAmount } from '@nimiq/vue-components';
import { Order, Payment, Currency, LegacyCurrency } from '../lib/Types';

@Component({ components: { Amount, FiatAmount } })
export default class OrderOverview extends Vue {
    @Prop({ type: Object, required: true })
    public order!: Order;

    @Prop(Object)
    public payment?: Payment;

    private DECIMALS: Record<Currency | LegacyCurrency, number> = {
        [Currency.NIM]: 5,
        [Currency.BTC]: 8,
        [Currency.USDC]: 6,
        [LegacyCurrency.ETH]: 18,
    };
}
